





































































import {Vue, Component, Mixins, Prop, Ref} from 'vue-property-decorator';
import PaginationMixin from "@/mixins/PaginationMixin";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI";
import Address from "@/models/Address";
import Handler from "@/handlers/Handler";
import Client from "@/models/Client";
import Headquarter from "@/models/Headquarter";
import AddressService from "@/services/AddressService";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import PotentialClientService from "@/services/PotentialClientService";
import HeadquarterService from "@/services/HeadquarterService";

@Component({})
export default class HeadquarterAddressesTab extends Mixins(PaginationMixin) {

  @Prop() readonly headquarter!: Headquarter
  @Ref() readonly form!: HTMLFormElement

  get lang() { return getModule(LangModule).lang }

  loading: boolean = false

  updateHeadquarter() {
    getModule(DialogModule).showDialog(new Dialog(
        this.lang.warning, this.lang.operationConfirmation, async () => {

          const headquarter: SingleItem<Headquarter> = {item: new Headquarter()}

          await Handler.getItem(this, headquarter, () =>
              HeadquarterService.update(this.headquarter.id!!, this.headquarter)
          )

        }
    ))
  }

}
