






































import {Vue, Component, Mixins, Prop} from 'vue-property-decorator';
import PaginationMixin from "@/mixins/PaginationMixin";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Address, {AddressParentTypes} from "@/models/Address";
import Handler from "@/handlers/Handler";
import Headquarter from "@/models/Headquarter";
import AddressService from "@/services/AddressService";
import CreateAddressDialog from "@/components/dialog/CreateAddressDialog.vue";

@Component({
  computed: {
    AddressParentTypes() {
      return AddressParentTypes
    }
  },
  components: {CreateAddressDialog}
})
export default class HeadquarterAddressesTab extends Mixins(PaginationMixin) {

  @Prop() readonly headquarter!: Headquarter;

  get lang() { return getModule(LangModule).lang }

  addresses: MultipleItem<Address> = { items: [], totalItems: 0 }
  loading: boolean = false
  dialog: boolean = false
  addressDetailsDialog: boolean = false
  address: Address = new Address()

  headers = [
    { text: this.lang.name, value: "name", width: "auto", align: "center" },
    { text: this.lang.name, value: "email", width: "auto", align: "center" },
    { text: this.lang.address, value: "address", width: "auto", align: "center" },
    { text: this.lang.postalCode, value: "postalCode", width: "auto", align: "center" },
    { text: this.lang.actions, value: "actions", width: "auto", align: "center" },
  ]

  created() {
    this.refresh()
  }

  async refresh() {
    await Handler.getItems(this, this.addresses, () =>
        AddressService.getHeadquarterAddresses(this.headquarter.id!!)
    )
  }

  addressDetail(address: Address) {
    this.addressDetailsDialog = true
    this.address = address
  }


  deleteAddress(address: Address) {

  }

}
